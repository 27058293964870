import moment from 'moment'

import { useEventSubscriptions } from '../services/eventService'
import { useSubscriptionPlans } from '../services/subscriptionPlanService'

export function useEventSubscription(eventId) {
  const { data: plans, isLoading: isPlansLoading } = useSubscriptionPlans()
  const { data: subscriptions, isLoading: isSubscriptionsLoading, mutate } = useEventSubscriptions(eventId)

  const subscription = subscriptions?.find((s) => s.paymentStatus === 'Succeed' && moment(s.endDate).isAfter(moment()))
  const plan = plans?.find((p) => p.id === subscription?.subscriptionPlanId)
  const hasSucceedSubscription = subscriptions?.some((s) => !!s.endDate)
  const hasActiveSubscription = !!subscription

  return {
    subscription,
    subscriptions,
    plan,
    hasActiveSubscription,
    hasSucceedSubscription,
    isLoading: isPlansLoading || isSubscriptionsLoading,
    mutate,
  }
}
