import axios from 'axios'
import useSWR from 'swr'

import useApiResponse from '../hooks/useApiResponse'
import { getToken, removeToken } from '../utils/cookie'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
})

api.interceptors.request.use((config) => {
  const token = getToken()
  if (token) {
    config.headers.authorization = `Bearer ${token}`
  }
  return config
})

api.interceptors.response.use(
  (res) => {
    return res
  },
  (error) => {
    if (error?.response?.status === 401) {
      removeToken()
    }
    throw error
  },
)

export default api

export function getCacheKey(url, params) {
  const cacheKey = [url]
  if (params) {
    cacheKey.push(JSON.stringify(params))
  }
  cacheKey.push(getToken())
  return cacheKey
}

function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export function useAPI(url, params, config = {}) {
  const { data, error, isValidating, isLoading, mutate } = useSWR(
    url && getCacheKey(url, params),
    async () => {
      try {
        const { data } = await api.get(url, { params, ...config })
        return data
      } catch (err) {
        // Adding delay before retrying
        await delay(5000) // Delay of 5 seconds
        throw err // Rethrow the error to trigger SWR's error handling
      }
    },
    {
      ...config,
      shouldRetryOnError: false, // Disable SWR’s built-in retry
    },
  )

  const cachedResponse = useApiResponse(data)

  return {
    data: cachedResponse,
    error,
    isLoading: !cachedResponse,
    isLoaded: !isLoading,
    isValidating,
    mutate,
  }
}
