import api, { useAPI } from './api'

// Get a organization record
export function useOrganization(organizationId) {
  return useAPI(organizationId && `/organizations/${organizationId}`)
}

// Update a organization record
export function updateOrganization(organizationId, organization) {
  return api.put(`/organizations/${organizationId}`, organization)
}

// Get list of organizations
export function useOrganizations() {
  return useAPI(`/organizations`)
}

// Get list of organizations
export function userOrganizations() {
  return useAPI(`/organizations/userOrganizations`)
}

export function useOrganizationEvents(organizationId) {
  return useAPI(`/organizations/${organizationId}/events`)
}

// Get list of organization members
export function useOrganizationMembers(organizationId) {
  return useAPI(`/organizations/${organizationId}/members`)
}

// Get list of organization assistants
export function useOrganizationAssistants(organizationId) {
  return useAPI(`/organizations/${organizationId}/assistants`)
}

// create a organization record
export function createOrganization(organization) {
  return api.post(`/organizations`, organization)
}

// delete a organization record
export function deleteOrganization(organizationId) {
  return api.delete(`/organizations/${organizationId}`)
}

// invite a member to organization
export function inviteOrganizationMember(organizationId, payload) {
  return api.post(`/organizations/${organizationId}/invite-member`, payload)
}

// remove a member from organization
export function deleteOrganizationMember(organizationId, userId) {
  return api.delete(`/organizations/${organizationId}/members/${userId}`)
}

// invite an assistant to organization
export function inviteOrganizationAssistant(organizationId, payload) {
  return api.post(`/organizations/${organizationId}/invite-assistant`, payload)
}

// remove an assistant from organization
export function deleteOrganizationAssistant(organizationId, userId) {
  return api.delete(`/organizations/${organizationId}/assistants/${userId}`)
}

// get user record with org role
export function useOrgUser(organizationId, userId) {
  return useAPI(`/organizations/${organizationId}/members/${userId}`)
}
